<template>
    <div>
        <pretty-select
            v-if="locales"
            placeholder="Language"
            :required="true"
            :options="filteredLocales"
            :reduce="locale => locale.tag"
            :get-option-label="locale => `${locale.name || ''} ${locale.location ? ' - ' + locale.location : ''} (${locale.tag})`"
            v-model="localValue"
            class="language-selector"
            :class="{'language-selector--is': !!localValue}"
        ></pretty-select>
        <span class="pretty-select-error" v-if="$v.$error">Language is required</span>
    </div>
</template>

<script>
import * as locales from 'locale-codes'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'LanguageSelector',
    components: { PrettySelect },
    props: {
        value: {
            default: null,
        },
        $v: {
            type: Object,
        },
    },
    data () {
        return {
            locales: locales.all.filter(locale => locale.name !== 'Pseudo Language'),
        }
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
        filteredLocales () {
            if (!this.locales || !this.locales.length) {
                return []
            }

            return this.locales.filter(e => !!e.location)
        },
    },
}
</script>
